@import "../../assets/styles/colors.scss";
@import "../../assets/styles/font-mixins";
@import "../../assets/styles/mixins";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $main-color;
  font-family: Roboto-Regular, sans-serif !important;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

i {
  cursor: pointer;
}

a {
  text-decoration: none;
  display: inline-block;
  color: unset;
}

a:visited {
  color: unset;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  margin: 0;
}

.main-padding {
  padding: 16px;
}

.main-padding-sides-only {
  padding: 0 16px;
}

.main-padding-vertical-only {
  padding: 16px 0;
}

.main-margin-small-top {
  margin-top: 8px;
}

.main-margin-small-bottom {
  margin-bottom: 8px;
}

.main-margin-small-left {
  margin-left: 8px;
}

.main-margin-small-right {
  margin-right: 8px;
}

.main-margin-top {
  margin-top: 16px;
}

.main-margin-bottom {
  margin-bottom: 16px;
}

.main-margin-left {
  margin-left: 16px;
}

.main-margin-right {
  margin-left: 16px;
}

.ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.h-full {
  height: 100%;
}

.modal-alert {
  .c-modal-content {
    border-radius: 4px;
    width: 90%;
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: $white;
}

.empty-result {
  text-align: center;
  margin-top: 20px;
}

.footer-buttons-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px;
  width: 100%;
  background-color: $white;
  gap: 8px;

  @include box-shadow(0px -2px 4px -1.1875px rgba(33, 37, 41, 0.1));
}

.button {
  flex: 1;
  @include label-large;
  border-radius: 2px !important;

  &-neutral {
    background-color: $middle-light-gray !important;
    border: 1px solid $gray-middle-dark !important;
  }

  &-primary {
    background-color: $blue-middle-dark !important;
    color: $white !important;

    &[disabled] {
      background-color: $middle-light-gray !important;
      color: $shade-gray !important;
    }
  }

  &-delete {
    background-color: $warning !important;;
    color: $white !important;
  }
}

.form-button {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: Roboto-Regular, sans-serif !important;
  letter-spacing: 0 !important;
  border-radius: 2px !important;
  border: 0 !important;
}

hr.delimiter {
  margin: 0 auto;
  border-top: 1.5px solid $middle-light-gray;
  width: 95%;
}
